import {
  BusinessModuleRef, CustomerAccount, CustomerOrderBase, CustomerProfile,
  CustomerSubscription, MapUtils, toData, OnlineProductOrder, OnlineTicketOrder,
  OperationResponse, PoiReview, SerializedData, VoidOperationResponse,
  fromDataSafe, fromData, ArtistPageInfo, TaxIdentificationDetails,
  BrandPageInfo, OrganizerPageInfo,
} from 'in-time-core';
import { Injectable, inject } from '@angular/core';
import { CloudFunctionsException, GCPCloudFunctionsProvider, ICloudFunctionsProvider } from 'cloud-functions';
import { IInTimeApiEndpointProvider, InTimeApiEndpointProvider } from './utility/in-time-api-endpoint-provider';
import { CloudFunctionsHttpClient } from './utility/cloud-functions-http-client';
import { AuthenticationService } from './authentication.service';
import { ErrorType, parseInTimeApiError } from '../core/models/error-type';
import { logError } from 'in-time-logger';
import { environment } from '../app.environment';
import { createHttpsAuthContext } from '../core/utils/cloud-function-utils';

interface ClientData {
  client: CustomerProfile,
  subscription: CustomerSubscription,
}

@Injectable({
  providedIn: 'root'
})
export class InTimeApiService {
  private readonly cloudFunctionsProvider: ICloudFunctionsProvider;
  private readonly endpointProvider: IInTimeApiEndpointProvider;

  private readonly authenticationService = inject(AuthenticationService);

  constructor() {
    this.cloudFunctionsProvider = new GCPCloudFunctionsProvider({
      httpClient: new CloudFunctionsHttpClient(),
      gcpServiceUrl: `${environment.apiGatewayUrl}/api`,
      appId: 'in-time-web',
      buildId: null,
      operatingSystem: 'browser',
    });

    if(environment.useApiEmulator) {
      this.cloudFunctionsProvider.useFunctionsEmulator('http://localhost:8081');
    }

    this.endpointProvider = InTimeApiEndpointProvider;
  }

  async createOnlineTicketOrder(
    order: OnlineTicketOrder
  ): Promise<OperationResponse<CustomerOrderBase>> {
    const endpoint = this.endpointProvider.createOnlineTicketOrder;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'order': toData(order),
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const orderResult = fromDataSafe<CustomerOrderBase>(response.data['result'] as SerializedData);
        if(orderResult.isError) {
          logError(`Failed to deserialize online ticket order {${order.uniqueId}} of customer {${order.creatorId}} from API response: ${orderResult.error}`);
          return OperationResponse.error(ErrorType.Unknown);
        }

        return OperationResponse.success(orderResult.value);
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.PaymentInternalError);
        logError(`Failed to create online ticket order {${order.uniqueId}} of customer {${order.creatorId}} because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async createOnlineProductOrder(
    order: OnlineProductOrder,
  ): Promise<OperationResponse<CustomerOrderBase>> {
    const endpoint = this.endpointProvider.createOnlineProductOrder;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'order': toData(order),
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const orderResult = fromDataSafe<CustomerOrderBase>(response.data['result'] as SerializedData);
        if(orderResult.isError) {
          logError(`Failed to deserialize online product order {${order.uniqueId}} of customer {${order.creatorId}} from API response: ${orderResult.error}`);
          return OperationResponse.error(ErrorType.Unknown);
        }

        return OperationResponse.success(orderResult.value);
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.PaymentInternalError);
        logError(`Failed to create online product order {${order.uniqueId}} of customer {${order.creatorId}} because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async markOrderWithFailedPayment(
    order: CustomerOrderBase,
  ): Promise<OperationResponse<CustomerOrderBase>> {
    const endpoint = this.endpointProvider.markOrderWithFailedPayment;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'orderId': order.uniqueId,
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const orderResult = fromDataSafe<CustomerOrderBase>(response.data['result'] as SerializedData);
        if(orderResult.isError) {
          logError(`Failed to deserialize order {${order.uniqueId}} of customer {${order.creatorId}} from API response: ${orderResult.error}`);
          return OperationResponse.error(ErrorType.Unknown);
        }

        return OperationResponse.success(orderResult.value);
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.PaymentInternalError);
        logError(`Failed to mark order {${order.uniqueId}} of customer {${order.creatorId}} with failed payment because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async abortOrder(
    order: CustomerOrderBase,
  ): Promise<OperationResponse<CustomerOrderBase>> {
    const endpoint = this.endpointProvider.abortOrder;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'orderId': order.uniqueId,
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const orderResult = fromDataSafe<CustomerOrderBase>(response.data['result'] as SerializedData);
        if(orderResult.isError) {
          logError(`Failed to deserialize order {${order.uniqueId}} of customer {${order.creatorId}} from API response: ${orderResult.error}`);
          return OperationResponse.error(ErrorType.Unknown);
        }

        return OperationResponse.success(orderResult.value);
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.PaymentInternalError);
        logError(`Failed to abort order {${order.uniqueId}} of customer {${order.creatorId}} because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async lockSeatSelection(selection: {
    businessRef: BusinessModuleRef,
    eventId: string,
    sessionId: string,
    seats: number[]
  }): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.lockSeatSelection;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'businessRef': selection.businessRef.encode(),
          'eventId': selection.eventId,
          'sessionId': selection.sessionId,
          'seats': selection.seats,
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidOperationResponse.success();
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.DatabaseError);
        // eslint-disable-next-line max-len
        logError(`Failed to lock seat selection for event {${selection.eventId}} of business {${selection.businessRef.uniqueId}} because of a cloud error: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async createPoiReview(review: PoiReview): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.createPoiReview;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'review': toData(review),
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidOperationResponse.success();
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.DatabaseError);
        logError(`Failed to create poi review {${review.uniqueId}} of business {${review.businessRef.uniqueId}} because of a cloud error: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async createClientFromCustomerAccount(
    account: CustomerAccount,
    businessRef: BusinessModuleRef,
    owningStaffId: string | null = null,
  ): Promise<OperationResponse<ClientData>> {
    const endpoint = this.endpointProvider.createClientFromCustomerAccount;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'businessRef': businessRef.encode(),
          'owningStaffId': owningStaffId,
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const map = response.data['result'] as SerializedData;
        const clientResult = fromDataSafe<CustomerProfile>(
          MapUtils.get<SerializedData>(map, 'client'),
        );

        const subscriptionResult = fromDataSafe<CustomerSubscription>(
          MapUtils.get<SerializedData>(map, 'subscription'),
        );

        if(clientResult.isError) {
          // eslint-disable-next-line max-len
          logError(`Failed to deserialize client profile from API response for account {${account.uniqueId}} to business {${businessRef}}: ${clientResult.error}`);
          return OperationResponse.error(ErrorType.Unknown);
        }

        if(subscriptionResult.isError) {
          // eslint-disable-next-line max-len
          logError(`Failed to deserialize client subscription from API response for account {${account.uniqueId}} to business {${businessRef}}: ${subscriptionResult.error}`);
          return OperationResponse.error(ErrorType.Unknown);
        }

        return OperationResponse.success({
          client: clientResult.value,
          subscription: subscriptionResult.value,
        });
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.DatabaseError);
        logError(`Failed to create client from customer account to business {${businessRef.encode()}} because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async validateTaxForm(requestId: string, details: TaxIdentificationDetails): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.validateTaxForm;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'requestId': requestId,
          'details': toData(details),
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidOperationResponse.success();
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.DatabaseError);
        logError(`Failed to validate tax form {${requestId}} because of a cloud error: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async fetchArtistPageInfo(
    webRoute: string,
  ): Promise<OperationResponse<ArtistPageInfo>> {
    const endpoint = this.endpointProvider.fetchArtistPageInfo;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'webRoute': webRoute,
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const data = response.data['result'] as SerializedData;
        const artistPageInfo = fromData<ArtistPageInfo>(data);

        return OperationResponse.success(artistPageInfo);
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.DatabaseError);
        logError(`Failed to fetch artist page info for web route {${webRoute}} because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async fetchBrandPageInfo(
    webRoute: string
  ): Promise<OperationResponse<BrandPageInfo>> {
    const endpoint = this.endpointProvider.fetchBrandPageInfo;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'webRoute': webRoute,
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const data = response.data['result'] as SerializedData;
        const brandPageInfo = fromData<BrandPageInfo>(data);

        return OperationResponse.success(brandPageInfo);
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.DatabaseError);
        logError(`Failed to fetch brand page info for web route {${webRoute}} because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async fetchOrganizerPageInfo(
    webRoute: string,
  ): Promise<OperationResponse<OrganizerPageInfo>> {
    const endpoint = this.endpointProvider.fetchOrganizerPageInfo;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(
        endpoint,
        context,
      );

      const response = await callable.call({
        parameters: {
          'webRoute': webRoute,
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const data = response.data['result'] as SerializedData;
        const organizerPageInfo = fromData<OrganizerPageInfo>(data);

        return OperationResponse.success(organizerPageInfo);
      }
      else {
        const errorType = parseInTimeApiError(MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.DatabaseError);
        logError(`Failed to fetch organizer page info for web route {${webRoute}} because of a cloud error: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call ${endpoint} function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }
}
